<template>
  <div>
    <div class="text-center p-3">
      <button
        type="button"
        class="btn btn-outline-success"
        data-toggle="modal"
        data-target="#editModal"
      >Промяна Текст</button>
    </div>
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalTitle">Промяна на текст</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-floating">
              <textarea
                v-model="inputText"
                rows="7"
                class="form-control"
                placeholder="Нов текст"
                id="floatingTextarea2"
                style="height: 100px"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Назад</button>
            <button @click="editHomeText()" type="button" class="btn btn-success">Промени</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeServie from "../../services/home.service";
export default {
  data() {
    return {
      inputText: ""
    }
  },
  methods: {
    editHomeText() {
      HomeServie.editHomeText(this.inputText)
      .then(() => {
        this.$router.go(0);
      })
    }
  }
};
</script>

<style>
</style>