<template>
  <div>
    <template v-if="showAdminBoard">
      <home></home>
    </template>
    <template v-else>
      <home-guest></home-guest>
    </template>
  </div>
</template>

<script>
import Home from './share/Home.vue';
import HomeGuest from './share/HomeGuest.vue';

export default {
  components: { Home, HomeGuest },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ROOT");
      }
      return false;
    },
  },
};
</script>

<style scoped>
</style>