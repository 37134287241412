<template>
  <span v-if="currentUser">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" id="mainNav">
      <router-link to="/">
        <img src="../../assets/logo.png" alt width="130" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarResponsive">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link class="nav-link" to="/">Start</router-link>
          </li>
          <li class="nav-item active">
            <router-link class="nav-link" to="/gallery">Galerie</router-link>
          </li>
          <li class="nav-item active">
            <a class="btn btn-outline-dark" href @click.prevent="logOut">Abmelden</a>
          </li>
        </ul>
      </div>
    </nav>
  </span>
  <!-- GUEST -->
  <span v-else>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" id="mainNav">
      <router-link to="/">
        <img src="../../assets/logo.png" alt width="130" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarResponsive">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link class="nav-link" to="/">Start</router-link>
          </li>
          <li class="nav-item active">
            <router-link class="nav-link" to="/gallery">Galerie</router-link>
          </li>
          <li class="nav-item active">
            <router-link class="nav-link" to="/contact">Kontakt</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </span>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  data() {
    return {
      input: "",
    };
  },
  validations: {
    input: {
      required,
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showRootBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ROOT");
      }

      return false;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    search(input) {
      this.$router.push(`/search/${input}`);
    },
  },
};
</script>

<style scoped>
a {
  font-style: italic;
}
.dropdown-menu {
  min-width: 5rem;
}
.search {
  color: green;
}
</style>