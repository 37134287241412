import VueRouter from 'vue-router';

import Home from './components/Home'
import NotFound from './components/NotFound';


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,

        },
        
        {
            path: '/lyubenov',
            component: () => import('./components/auth/Login'),
            meta: {
                unauthenticated: true
            }
        },
        {
            path: '/forgot-password',
            component: () => import('./components/auth/ForgotPassword'),
            meta: {
                unauthenticated: true
            }
        },
        {
            path: '/register',
            component: () => import('./components/auth/Register'),
            meta: {
                unauthenticated: true
            }
        },
        {
            path: '/contact',            
            name: "Contact",
            component: () => import('./components/Contact'),
        },
        {
            path: '/gallery',            
            name: "Gallery",
            component: () => import('./components/Gallery'),
        },
        {
            path: '/datenschutz',            
            name: "Datenschutz",
            component: () => import('./components/Datenschutz'),
        },
        {
            path: '*',
            component: NotFound
        }
    ]
});

// HANDLER UNAUTHORIZIERED
router.beforeEach((to, from, next) => {
    const publicPages = 
    ['/contact', '/gallery', '/datenschutz',
    '/lyubenov', '/register', '/forgot-password', '/',
    ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to lyubenov page
    if (authRequired && !loggedIn) {
        next('/');
    } else {
        next();
    }
});
export default router;