<template>
  <div class="index">
    <div class="row row-cols-3">
      <div class="col-2"></div>
      <div class="col-8 home shadow-lg p-3 mb-5 bg-body rounded">
        <div class="home-image">
          <h1>Lyubenov Bau</h1>
        </div>
        <div>
          <div class="text-abaut">
            <h1>Unsere Leistungen</h1>
          </div>
          <div class="row row-cols-3 lestungen">
            <div class="col">
              <ul>
                <li class="li-i">
                  <i class="fas fa-tape"></i>
                </li>
                <li>
                  <h4>Trockenbau</h4>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul>
                <li class="li-i">
                  <i class="fas fa-pencil-ruler"></i>
                </li>
                <li>
                  <h4>Asphaltierer (ohne Straßebau)</h4>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul>
                <li class="li-i">
                  <i class="fas fa-toolbox"></i>
                </li>
                <li>
                  <h4>Holz- und Bautenschützer</h4>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul>
                <li class="li-i">
                  <i class="fas fa-layer-group"></i>
                </li>
                <li>
                  <h4>Bodenlegen</h4>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul>
                <li class="li-i">
                  <i class="fab fa-digital-ocean"></i>
                </li>
                <li>
                  <h4>Gebäudereiniger</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div class="text-abaut abaut">
            <h1 class="abaut-text">Über uns</h1>
            <h4 class="abaut-text">
              <p>Sie sind auf der Suche nach einem zuverlässigen, schnellen und kompetenten Handwerker? Dann sind Sie bei uns genau richtig!</p>
              <p>Durch unsere Kompetenz und Erfahrung sind wir Ihr perfekter Partner für Ihr Projekt.</p>
              <p>Ob Beratung, Planung oder Renorierung, wird sind für Sie da.</p>
              <p>Egal wie groß oder klein Ihr Auftrag ist, wir scheuen keine Herausforderung. Von kompletter Fassadengestaltung bis hin zum wechseln einer Glühbirne - wir unterstützen Sie wo Sie uns brauchen</p>
              <p>Nehmen Sie gerne Kontakt zu uns auf und wir besprechen Ihr Projekt persönlich</p>
            </h4>
          </div>
          <template v-if="showAdminBoard">
            <edit-home-text></edit-home-text>
          </template>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
import EditHomeText from "./EditHomeText.vue";
import HomeText from "../../mixins/home-mixin";

export default {
  mixins: [HomeText],
  components: {
    EditHomeText,
  },
  created() {
    this.getHomeText();
  },
  computed: {
    hasText() {
      return this.home;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ROOT");
      }
      return false;
    },
  },
};
</script>

<style scoped>
.abaut {
  background: darkgrey;
}
.abaut-text {
  color: white;
}
.text-abaut {
  text-align: center;
  padding: 2rem;
}
ul {
  padding: 0;
}
.li-i {
  padding-bottom: 1rem;
  text-align: center;
}
i {
  font-size: 5rem;
}
li {
  list-style: none;
  text-align: center;
}
.home {
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
  padding: 0 !important;
}
.home-image > h1 {
  text-align: center;
  padding: 15%;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px rgb(187 190 184), 0 0 5px #676767;
  font-size: xxx-large;
}
.home-image {
  background: url("../../assets/image/home-image2.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 400px;
}

@media (max-width: 900px) {
  .home-image {
    background: url("../../assets/image/home-image2.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 400px;
  }
  .lestungen {
    display: block;
  }
  .row-cols-3 > * {
    flex: 100% !important;
    max-width: 100% !important;
  }
}
</style>
