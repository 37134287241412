import axios from 'axios';
import API_URL from '../plugins/api-path';
import authHeader from '../services/auth-header';

export default {
    data() {
        return {
            home: []
        }
    },
    methods: {
        async getHomeText() {
            try {
                const res = await axios.get(API_URL() + `/home/text`, { headers: authHeader() });
                this.home = res.data;
            } catch (error) {
                if (error.response.status === 401) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                }
                this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
            }
        },
        
        async getHomeTextGuest() {
            try {
                const res = await axios.get(API_URL() + `/guest/text`, { headers: authHeader() });
                this.home = res.data;
            } catch (error) {
                if (error.response.status === 401) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                }
                this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
            }
        }
    },

    
}