<template>
  <div>
    <div class="footer">
      <ul>
        <li>
          <i class="fas fa-envelope-square"
            ><a href="mailto:email@lyubenov-bau.de">
              email@lyubenov-bau.de</a
            ></i
          >
        </li>
        <br />
        <li>
          <address>
            <i class="fas fa-phone-volume"
              ><a href="tel:0172 4277950"> 0172 4277950</a></i
            >
          </address>
        </li>
        <li>
          <address>
            <i class="fas fa-phone-volume"
              ><a href="tel:0162 7363709"> 0162 7363709</a></i
            >
          </address>
        </li>
        <li>
          <p style="/* font: 400 16px Arial; */">
            <i class="fas fa-globe-europe"></i> Montabaur - Kreis
          </p>
        </li>
        <li>
          <p style="/* font: 400 16px Arial; */">
            <i class="fas fa-globe-europe"></i> Kaiserslautern - Kreis
          </p>
        </li>
      </ul>
    </div>
    <div class="desing">
      <div class="desing-p">
        <router-link to="/datenschutz">
          Impressum & Datenschutzerklärung
        </router-link>
      </div>
    </div>
    <footer>
      <cookie-law theme="dark-lime"></cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw },
};
</script>

<style scoped>
a {
  color: white !important;
}
.desing {
  display: flex;
  background: gray;
  color: black;
}
.desing-p > p {
  margin: 0;
}
.desing-p {
  margin: 0 auto;
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}
ul {
  margin: 0;
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}
li {
  list-style: none;
}
.footer {
  background: black;
  color: white;
}

@media (max-width: 900px) {
  ul {
    display: block;
    text-align: center;
  }
  .desing-p {
    display: block;
    text-align: center;
  }
}
</style>