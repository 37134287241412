<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
      integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
      crossorigin="anonymous"
    />
    <app-navigation></app-navigation>
    <router-view></router-view>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppNavigation from "./components/core/Navigation";
import AppFooter from "./components/core/Footer"

export default {
  name: "App",
  components: {
    AppNavigation,
    AppFooter
  },
};
</script>

<style>
.VueCarousel-dot-container {
  margin-top: 0px !important;
}
.VueCarousel-dot-container > button {
  margin-top: 0px !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
a:hover {
  text-decoration: none !important;
}
.index {
  padding: 2rem !important;
}
</style>
