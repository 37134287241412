import axios from 'axios';
import authHeader from "./auth-header";

import API_URL from '../plugins/api-path';

class RecipeService {
    async editHomeText(text) {
        const formData = new FormData();
        formData.append('text', text);

        try {
            const response = await axios
                .post(API_URL() + `/home/edit`, formData, { headers: authHeader() });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                this.$store.dispatch("auth/logout");
                this.$router.push("/login");
            }
            this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
        }
    }
}

export default new RecipeService();