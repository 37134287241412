import Vue from 'vue'
import App from './App.vue'
import router  from './router';
import store  from './store';
import VueRouter from 'vue-router';
import VueObserveVisibility from 'vue-observe-visibility'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import VueCarousel from 'vue-carousel';
 
Vue.use(VueCarousel);
Vue.use(VueObserveVisibility)
Vue.use(VueRouter);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
